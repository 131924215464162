@font-face {
  font-family: 'Orienta', sans-serif;
  src: url(https://fonts.cdnfonts.com/css/orienta);
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  text-align: justify;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Orienta', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: white;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: 'Orienta', sans-serif;
}