*{
    scroll-behavior: smooth;
}

:root {
    --white: #ffffff;
    --gray: #b6b6b6;
    --dark-gray: #5e5e5e;
    --main-red: #822516;
    --light-main-red: #9E2B10;
    --main-brown: #B26F38;
    --light-main-brown: #D19966;
    --sunset: #e85b46;
    --black: #000000;
    --dark: #121212;
    --not-white: #f9f9f9;
    --blue-wave: #1a85b3;
    --pink: #ED89CB;
    --lilac: #BB85E5 ;
    --dark-purple: #211c43;
}