/* styles.css */

.sidebar {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 0%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--dark);
  transition: width 0.5s;
  z-index: 100;
  color: white;
}

.sidebar.open {
  width: 50%;
}

.toggle {
  position: relative;
  top: 50%;
  right: 0px;
  color: var(--dark-gray);
  cursor: pointer;
  justify-content: center;
  padding: 2rem;
  margin-right: 10rem;
  background: var(--dark);
  border: 1px solid var(--gray);
}

.toggle:hover {
  background-color: var(--dark-gray);
  color: var(--white);
  border-color: var(--dark);
}

.toggle-button-exit {
  cursor: pointer;
  border: none;
  font-size: 1.5rem;
  position: relative;
  top: 2%;
}

.sidebar-content {
  padding: 2rem;
  overflow-x: hidden;
}

.formContainer {
  width: 100%;
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}

.form_messeng {
  margin-top: 2rem;
}

#contactUs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.3rem;
  width: 100%;
}

#contactUs form {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  width: 100%;
}

#contactUs form input {
  width: calc(100% - 0.9rem);
  height: 1.5rem;
  padding: 5rem 0;
  border-radius: 0.2rem;
  border: none;
  font-size: 0.9rem;
  padding: 0.4rem;
}

#contactUs form input:focus {
  border: none;
  outline: none;
}

#contactUsLines {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
}

#contactUs button {
  align-items: center;
  justify-content: center;
}
.form-button {
  margin: 1rem;
  display: block;
  font-size: 1.1rem;
  z-index: 1;
  transition: .5s;
  overflow: hidden;
  letter-spacing: .1rem;
  position: relative;
}

.form-button:hover{
  background: var(--white);
  border-color: var(--dark);
  color: var(--black);
  transition: .5s;
}

.form-button::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--white);
  z-index: -1;
  transition: .6s;
}

.form-button:hover::before {
  width: 100%;
}

.contactContainer {
  width: 100%;
}

.contactContainer label {
  font-size: 1.1rem;
}

button {
  cursor: pointer;
  background-color: var(--dark);
  border: 1px solid var(--gray);
  padding: 0.75rem 2rem;
  border-radius: 0.25rem;
  color: white;
}

.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--black);
  color: var(--sunset);
  padding: 15px;
  border-radius: 5px;
  animation: fadeInOut 5s ease-in-out;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

#networks{
  margin-top: 5rem;
}
.network {
  width: 2rem;
  background-color: var(--dark);
  border-radius: 0.3rem;
}

.networks_list{
  padding: 0;
  display: flex;
  gap: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-height: 860px) {
  .sidebar {
    overflow-y: auto;
  }
  #networks {
    display: flow;
    margin-top: 2rem;
    position: static;
  }
}

@media only screen and (max-width: 990px) {
  .sidebar.open {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 1.4rem;
    line-height: 1.3;
  }

  .quote {
    font-size: 1.1rem;
  }

  .form-button{
    font-size: 1rem;
  }

  .contactContainer label {
    font-size: 1rem;
  }

}

@media only screen and (max-width: 560px) {
  .sidebar.open {
    width: 95%;
  }

  .formContainer {
    display: block;
    margin: 0;
  }

  .sidebar {
    overflow: auto;
  }
  
  #contactUs form input {
    width: calc(100% - 0.9rem);
    margin-bottom: 0.5rem;
  }
  
  #contactUsLines {
    display: block;
    flex-wrap: none;
  }

  #contactUsLines label {
    margin: .8rem 0 .2rem 0;
    display: flex;
  }

  @media only screen and (max-width: 320px) {
    .quote{
      font-size: 1rem;
    }

    h2 {
      font-size: 1.2rem;
    }
  }
}